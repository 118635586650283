'use strict';
DIVERA247.directive('inputDateTime', function($compile, $document, moment, $timeout, $mdToast, SYSTEM)
{
	return {
		'restrict': 'E',
		scope: {
			format: '<',
			model: '=',
			label: '<',
			syncChanges: '<',
			syncChangesModel: '=',
			showDate: '<',
			showTime: '<',
			field: '<',
			minDate: '<',
			maxDate: '<',
			position: '@'
		},
		controller: function($scope)
		{
			$scope.openedDatepicker = false;
			$scope.openedTimepicker = false;

			$scope.inputDate = '';
			$scope.inputTime = '';

			$scope.inputDateError = '';
			$scope.inputTimeError = '';

			$scope.today = moment();
			$scope.todayTS = moment().hour(0).minute(0).second(0).millisecond(0).valueOf();

			$scope.selectedTS = 0;

			$scope.monthPointer = moment().date(1);

			$scope.days = {};

			if(typeof($scope.position) == 'undefined' || $scope.position != 'bottom')
			{
				$scope.position = 'top'
			}
		},
		'link': function ($scope, element, attr, ctrl)
		{
			var Userformats = {
				'date': 'DD.MM.YYYY',
				'datetime': 'DD.MM.YYYY HH:mm',
				'time': 'HH:mm',
				'unix': 'X'
			};

			var DBformats = {
				'date': 'YYYY-MM-DD',
				'datetime': 'YYYY-MM-DD HH:mm:ss',
				'time': 'HH:mm',
				'unix': 'X'
			};

			if(!DBformats.hasOwnProperty($scope.format))
			{
				$scope.format = 'datetime';
			}

			var timeout1 = null;
            var timeout2 = null;
            var tempMaxDateMoment = null;
			var tempMinDateMoment = null;

			var updateModel = function(type)
			{
				if($scope.syncChanges && typeof($scope.syncChangesModel) != 'undefined')
				{
					var DTbefore = moment($scope.model,DBformats[$scope.format]);
					var DTafter = $scope.moment.clone();
					var momentToChange = moment($scope.syncChangesModel,DBformats[$scope.format]);
					var tempDifference = DTafter.unix()-DTbefore.unix();

					if(type=='date')
					{
						var tempDifferenceToDays = moment.duration(tempDifference,'seconds').asDays();

						if(tempDifferenceToDays<0)
						{
							momentToChange.subtract(tempDifferenceToDays*-1,'days');
						}
						else if(tempDifferenceToDays>0)
						{
							momentToChange.add(tempDifferenceToDays,'days');
						}
					}
					else if(type=='time')
					{
						if(tempDifference<0)
						{
							momentToChange.subtract(tempDifference*-1,'seconds');
						}
						else if(tempDifference>0)
						{
							momentToChange.add(tempDifference,'seconds');
						}
					}

					$scope.syncChangesModel = momentToChange.format(DBformats[$scope.format]);

					if($scope.format == 'unix')
					{
						$scope.syncChangesModel = parseInt($scope.syncChangesModel,10);
					}
				}

				$scope.model = $scope.moment.format(DBformats[$scope.format]);

				if($scope.format == 'unix')
				{
					$scope.model = parseInt($scope.model,10);
				}

				$scope.monthPointer = $scope.moment.clone();
				$scope.monthPointer.date(1);
				$scope.buildMonth();

				var tempSelected = $scope.moment.clone();
				tempSelected.hour(0).minute(0).second(0).millisecond(0);
				$scope.selectedTS = tempSelected.unix()*1000;

				if(typeof($scope.field)!='undefined')
				{
					jQuery($scope.field).attr('value',$scope.model);
				}
			};

			$scope.toggleDatepicker = function()
			{
				$scope.openedDatepicker = ($scope.openedDatepicker) ? false : true;
				if($scope.openedDatepicker)
				{
					$scope.monthPointer = $scope.moment.date(1);
					$scope.buildMonth();
				}
				else
				{
					element.find('input').focus();
				}
			};

			$scope.toggleTimepicker = function()
			{
				$scope.openedTimepicker = ($scope.openedTimepicker) ? false : true;
			};

			$scope.changeInputDate = function()
			{
				$scope.inputDateError = '';
				var temp = moment($scope.inputDate, ['DD.MM.YYYY','DD.M.YYYY','D.M.YYYY','D.MM.YYYY','DD.MM.YY','D.MM.YY','D.M.YY','DD.M.YY'], 'de', true);

				if(temp.isValid())
				{
					$scope.inputTimeError = '';
					$scope.moment.date(temp.date());
					$scope.moment.month(temp.month());
					$scope.moment.year(temp.year());
					updateModel('date');
					validate(temp);
				}
				else
				{
					$scope.inputDateError = 'Ungültiges Datum: Angabe im Format DD.MM.JJJJ tätigen, z.B. 01.02.2017.';
				}
			};

			$scope.changeInputTime = function()
			{
				var temp1 = moment($scope.inputTime, 'HH:mm', 'de', true);

				if(temp1.isValid())
				{
					$scope.inputTimeError = '';
					$scope.moment.hour(temp1.hour());
					$scope.moment.minute(temp1.minute());
					$scope.moment.second(0);
					updateModel('time');
					validate($scope.moment);
				}
				else
				{
					var temp2 = moment($scope.inputTime, 'H:mm', 'de', true);
					if(temp2.isValid())
					{
						$scope.inputTimeError = '';
						$scope.moment.hour(temp2.hour());
						$scope.moment.minute(temp2.minute());
						$scope.moment.second(0);
						updateModel('time');
						validate($scope.moment);
					}
					else
					{
						$scope.inputTimeError = 'Ungültige Zeitangabe: Angabe im Format HH:MM tätigen, z.B. 12:30.';
					}
				}
			};

			$scope.chooseDay = function(day)
			{
				var temp = moment(day);
				if(temp.isValid())
				{
					$scope.moment.date(temp.date());
					$scope.moment.month(temp.month());
					$scope.moment.year(temp.year());
					updateModel('date');
				}
			};

			$scope.buildMonth = function()
			{
				var tempDayPointer = moment($scope.monthPointer).hour(0).minute(0).second(0).millisecond(0);
				var daysInMonth = tempDayPointer.daysInMonth();
				$scope.days = [];

				for(var i = 0; i < daysInMonth; i++)
				{
					var tempDay = {
						ts: tempDayPointer.valueOf()
					};

					$scope.days.push(tempDay);
					tempDayPointer.add(1, 'day');
				}
			};

			$scope.prevMonth = function()
			{
				$scope.monthPointer.subtract(1, 'months');
				$scope.buildMonth();
			};

			$scope.nextMonth = function()
			{
				$scope.monthPointer.add(1, 'months');
				$scope.buildMonth();
			};

			element.on("click", function(e)
			{
				if($scope.openedDatepicker)
				{
					e.stopPropagation();
				}
			});

			$document.on("click", function(e)
			{
				if (!$scope.openedDatepicker) {
					return;
				}

				$scope.$apply(function () {
					$scope.openedDatepicker = false;
				});
			});

			var validate = function(temp)
			{
				if(tempMinDateMoment != null && tempMinDateMoment.isValid() && temp.isBefore(tempMinDateMoment))
				{
					$scope.inputTimeError = 'Tag/Zeit muss größer sein als ' + tempMinDateMoment.format(Userformats.datetime) + ' Uhr.';
					$mdToast.show($mdToast.default().textContent($scope.inputTimeError));
					return false;
				}

				if(tempMaxDateMoment != null && tempMaxDateMoment.isValid() && temp.isAfter(tempMaxDateMoment))
				{
					$scope.inputTimeError = 'Tag/Zeit muss kleiner sein als ' + tempMaxDateMoment.format(Userformats.datetime) + ' Uhr.';
					$mdToast.show($mdToast.default().textContent($scope.inputTimeError));
					return false;
				}

				$scope.inputTimeError = '';

				return true;
			};

			var init = function()
			{
				if(typeof($scope.maxDate) != 'undefined')
				{
					tempMaxDateMoment = moment($scope.maxDate,DBformats[$scope.format]);
				}

				if(typeof($scope.minDate) != 'undefined')
				{
					var tempMinDateMoment = moment($scope.minDate,DBformats[$scope.format]);
				}

				if($scope.model == '' || $scope.model == 0)
				{
                    $scope.moment = moment();
				}
				else if($scope.model === parseInt($scope.model, 10) && $scope.model > 0)
				{
                    $scope.moment = moment($scope.model*1000);
				}
				else
				{
					$scope.moment = moment($scope.model);
                }

				if(!$scope.moment.isValid())
				{
					$scope.moment = moment();
				}

				$scope.selectedTS = moment($scope.moment).hour(0).minute(0).second(0).millisecond(0).valueOf();
				$scope.inputDate = $scope.moment.format(Userformats.date);
				$scope.inputTime = $scope.moment.format(Userformats.time);
				updateModel();

				if(typeof($scope.field)!='undefined')
				{
					jQuery($scope.field).attr('value',$scope.model);
				}
			};

			init();

			$scope.$watch('model',function(newV,oldV)
			{
				if(newV != oldV)
				{
					init();
				}
			});

			$scope.$watch('minDate',function(newV,oldV)
			{
				if(typeof($scope.minDate) != 'undefined' && $scope.minDate != '')
				{
					tempMinDateMoment = moment($scope.minDate,DBformats[$scope.format]);
				}
				else
				{
					tempMinDateMoment = null;
				}
			});

			$scope.$watch('maxDate',function(newV,oldV)
			{
				if(typeof ($scope.maxDate) != 'undefined' && $scope.maxDate != '')
				{
					tempMaxDateMoment = moment($scope.maxDate, DBformats[$scope.format]);
				}
				else
				{
					tempMaxDateMoment = null;
				}
			});
		},
		replace: true,
		templateUrl: 'res/'+SYSTEM.res+'/js/src/templates/divera247.app.directive.input-date-time.html'
	};
});
