'use strict';
DIVERA247.controller('OrderFormController', function($scope,$timeout)
    {
        $scope.errors = {cluster_credit_request_brutto:''};
        $scope.countries = {};
        $scope.tax = {};
        $scope.today = new Date;
        $scope.taxFactor = 0;
        $scope.priceAboItemBruttoRaw = 0.49;
        $scope.priceAlarmserverItemBruttoRaw = 4.99;
        $scope.priceAboTotalBruttoRaw = 0.0;
        $scope.priceAlarmserverTotalBruttoRaw = 0.0;
        $scope.priceSetupBruttoRaw = 49.99;
        $scope.priceSponsoringBruttoRaw = 49.99;
        $scope.priceAlarmserverSetupBruttoRaw = 99.99;
        $scope.pricePrepaidBruttoRaw = 0;
        $scope.pricePrepaidNettoRaw = 0;
        $scope.pricePrepaidUstRaw = 0;
        $scope.priceTotalBruttoRaw = 0;
        $scope.priceTotalUstRaw = 0;
        $scope.priceAboItemBrutto = '0,49';
        $scope.priceAlarmserverItemBrutto = '4,99';
        $scope.priceAlarmserverTotalBrutto = '4,99';
        $scope.priceAboTotalBrutto = '0,00';
        $scope.priceSetupBrutto = '49,99';
        $scope.priceSponsoringBrutto = '49,99';
        $scope.priceAlarmserverSetupBrutto = '99,99';
        $scope.pricePrepaidBrutto = '0,00';
        $scope.pricePrepaidNetto = '0,00';
        $scope.pricePrepaidUst = '0,00';
        $scope.priceTotalBrutto = '0,00';
        $scope.priceTotalNetto = '0,00';
        $scope.priceTotalUst = '0,00';

        $scope.order = {
            new_record: false,
            cluster_address: '',
            cluster_city: '',
            cluster_clustercategory_id: 1,
            cluster_country: '',
            cluster_name: '',
            pricing_persons: 0,
            invoice_legal_form: 0,
            cluster_zip: '',
            contact_email: '',
            contact_firstname: '',
            contact_gender: 'm',
            contact_lastname: '',
            contact_phone: '',
            invoice_account_bic: '',
            invoice_account_iban: '',
            invoice_account_owner: '',
            invoice_account_reference: '',
            invoice_address: '',
            invoice_city: '',
            invoice_different_address: 0,
            invoice_name: '',
            invoice_type: 0,
            invoice_zip: '',
            sponsoring: 0
        };

        $scope.test = {
            alarmserver: 0,
            alarmparser: 0,
        };

        $scope.init = function(order,region,tax,test)
        {
            $scope.order = unescapeObj(order);
            $scope.tax = tax;
            $scope.test = unescapeObj(test);

            for(var k in region) {
                $scope.countries[k] = {name:unescapeObj(region[k]),shortname:k};
            }

            if($scope.countries.hasOwnProperty($scope.order.cluster_country)) {
                $scope.order.cluster_country = $scope.countries[$scope.order.cluster_country];
            } else {
                $scope.order.cluster_country = $scope.countries['de'];
            }

            if(!$scope.order.new_record)
            {
                $scope.priceSetupBruttoRaw = 0;
                $scope.priceSetupBrutto = '0,00';
            }

            $scope.calc();
        };

        $scope.calc = function()
        {
            $scope.errors.cluster_credit_request_brutto = '';
            $scope.taxFactor = 19;

            if(typeof($scope.tax[$scope.order.cluster_country]) != 'undefined')
            {
                $scope.taxFactor = $scope.tax[$scope.order.cluster_country]['standard'];
            }

            $scope.priceAboTotalBruttoRaw = $scope.priceAboItemBruttoRaw*$scope.order.pricing_persons*$scope.order.pricing_period;
            $scope.priceAlarmserverTotalBruttoRaw = ($scope.order.alarmserver) ? ($scope.priceAlarmserverItemBruttoRaw*$scope.order.pricing_period) : 0;

            $scope.priceAboTotalBrutto = $scope.priceAboTotalBruttoRaw.toFixed(2).replace('.',',');
            $scope.priceAlarmserverTotalBrutto = $scope.priceAlarmserverTotalBruttoRaw.toFixed(2).replace('.',',');
            $scope.pricePrepaidBruttoRaw = 0;
            $scope.pricePrepaidBrutto = '0,00';

            if($scope.order.cluster_credit_request == 1)
            {
                if($scope.order.cluster_credit_request_brutto != 0 && typeof($scope.order.cluster_credit_request_brutto)=='number')
                {
                    $scope.pricePrepaidBruttoRaw = parseFloat($scope.order.cluster_credit_request_brutto);
                    $scope.pricePrepaidNettoRaw = $scope.pricePrepaidBruttoRaw / parseInt(100 + $scope.taxFactor) * 100;
                    $scope.pricePrepaidNetto = $scope.pricePrepaidNettoRaw.toFixed(2).toString().replace('.',',');
                    $scope.pricePrepaidBrutto = $scope.pricePrepaidBruttoRaw.toFixed(2).replace('.',',');
                    $scope.pricePrepaidUstRaw = $scope.pricePrepaidBruttoRaw / parseInt(100 + $scope.taxFactor) * $scope.taxFactor;
                    $scope.pricePrepaidUst = $scope.pricePrepaidUstRaw.toFixed(2).replace('.',',');

                    if($scope.order.cluster_credit_request_brutto < 20)
                    {
                        $scope.errors.cluster_credit_request_brutto = 'Mindestbetrag 20,- €.';
                    }
                }
                else
                {
                    $scope.errors.cluster_credit_request_brutto = 'Format ungültig, bitte ganze Zahlen angeben. Mindestbetrag 20,- €.';
                }
            }

            $scope.priceAlarmserverSetupBruttoRaw = ($scope.order.alarmserver) ? $scope.priceAlarmserverSetupBruttoRaw : 0;
            $scope.priceSponsoringBruttoRaw = ($scope.order.sponsoring) ? $scope.priceSponsoringBruttoRaw : 0;

            $scope.priceTotalBruttoRaw = parseFloat($scope.priceAboTotalBruttoRaw + $scope.priceSetupBruttoRaw + $scope.priceAlarmserverSetupBruttoRaw + $scope.priceAlarmserverTotalBruttoRaw + $scope.priceSponsoringBruttoRaw + $scope.pricePrepaidBruttoRaw);
            $scope.priceTotalBrutto = $scope.priceTotalBruttoRaw.toFixed(2).replace('.',',');
            $scope.priceTotalNettoRaw = $scope.priceTotalBruttoRaw / parseInt(100 + $scope.taxFactor) * 100;
            $scope.priceTotalNetto = $scope.priceTotalNettoRaw.toFixed(2).replace('.',',');
            $scope.priceTotalUstRaw = $scope.priceTotalBruttoRaw / parseInt(100 + $scope.taxFactor) * $scope.taxFactor;
            $scope.priceTotalUst = $scope.priceTotalUstRaw.toFixed(2).replace('.',',');

            return true;
        }
    });
