'use strict';
var DIVERA247 = angular.module('DIVERA247', ['ngRoute', 'ngSanitize', 'ngMaterial', 'ngDialog', 'LocalStorageModule', 'angularMoment'])

.config(function(SYSTEM, ngDialogProvider, $mdThemingProvider, $mdToastProvider)
{
    $mdThemingProvider.theme('backend')
        .primaryPalette('customPrimary')
        .accentPalette('customAccent')
        .warnPalette('customWarn')
        .backgroundPalette('customBackground');

    var customPrimary = {
        '50': '#0a6cff',
        '100': '#0060f0',
        '200': '#0055d6',
        '300': '#004bbd',
        '400': '#0041a3',
        '500': '#00378a',
        '600': '#002d70',
        '700': '#002357',
        '800': '#00183d',
        '900': '#000e24',
        'A100': '#247bff',
        'A200': '#3d8bff',
        'A400': '#579aff',
        'A700': '#00040a'
    };
    $mdThemingProvider
        .definePalette('customPrimary',
            customPrimary);

    var customAccent = {
        '50': '#560608',
        '100': '#6d080a',
        '200': '#850a0c',
        '300': '#9d0c0e',
        '400': '#b40d11',
        '500': '#cc0f13',
        '600': '#ee2024',
        '700': '#f0383b',
        '800': '#f25053',
        '900': '#f4676a',
        'A100': '#ee2024',
        'A200': '#e41115',
        'A400': '#cc0f13',
        'A700': '#f57f81'
    };
    $mdThemingProvider
        .definePalette('customAccent',
            customAccent);

    var customWarn = {
        '50': '#ffa06c',
        '100': '#ff8f53',
        '200': '#ff7f39',
        '300': '#ff6e20',
        '400': '#ff5e06',
        '500': '#ec5300',
        '600': '#d24a00',
        '700': '#b94100',
        '800': '#9f3800',
        '900': '#862f00',
        'A100': '#ffb186',
        'A200': '#ffc19f',
        'A400': '#ffd2b9',
        'A700': '#6c2600'
    };
    $mdThemingProvider
        .definePalette('customWarn',
            customWarn);

    var customBackground = {
        '50': '#ffffff',
        '100': '#ffffff',
        '200': '#ffffff',
        '300': '#f7f7f7',
        '400': '#ebebeb',
        '500': '#dedede',
        '600': '#d1d1d1',
        '700': '#c4c4c4',
        '800': '#b8b8b8',
        '900': '#ababab',
        'A100': '#ffffff',
        'A200': '#ffffff',
        'A400': '#ffffff',
        'A700': '#9e9e9e'
    };

    $mdThemingProvider
        .definePalette('customBackground',
            customBackground);

    $mdThemingProvider.setDefaultTheme('backend');//.dark();

    $mdThemingProvider.enableBrowserColor({
        theme: 'backend', // Default is 'default'
        palette: 'primary', // Default is 'primary', any basic material palette and extended palettes are available
        hue: '900' // Default is '800'
    });

    var activeToastContent;

    $mdToastProvider.addPreset('simple', {
        argOption: 'textContent',
        methods: ['textContent', 'content', 'action', 'highlightAction', 'highlightClass', 'theme', 'parent' ],
        options: ["$mdToast", "$mdTheming", function($mdToast, $mdTheming)
        {
            return {
                hideDelay : 5000,
                position : 'bottom right',
                templateUrl: 'res/' + SYSTEM.res + '/js/src/templates/toast/default.html',
                controller: ["$scope", function mdToastCtrl($scope) {
                    var self = this;
                    self.content = self.textContent;
                    this.resolve = function() {
                        $mdToast.hide( ACTION_RESOLVE );
                    };
                }],
                controllerAs: 'toast',
                bindToController: true
            };
        }]
    });

    $mdToastProvider.addPreset('default', {
        argOption: 'textContent',
        methods: ['textContent', 'content', 'action', 'highlightAction', 'highlightClass', 'theme', 'parent' ],
        options: ["$mdToast", "$mdTheming", function($mdToast, $mdTheming)
        {
            return {
                hideDelay : 5000,
                position : 'bottom right',
                templateUrl: 'res/' + SYSTEM.res + '/js/src/templates/toast/default.html',
                controller: ["$scope", function mdToastCtrl($scope) {
                    var self = this;
                    self.content = self.textContent;
                    this.resolve = function() {
                        $mdToast.hide( ACTION_RESOLVE );
                    };
                }],
                controllerAs: 'toast',
                bindToController: true
            };
        }]
    });
});